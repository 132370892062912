var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container container--md-50" },
    [
      _c(
        "div",
        {
          staticClass:
            "elementPanel elementPanel__sub elementPanel__sub--withActionbar"
        },
        [
          !_vm.isLoading
            ? _c("DynamicForm", {
                attrs: {
                  reportId: _vm.reportModel.uuid,
                  reportType: _vm.reportModel.type
                    ? _vm.reportModel.type.uuid
                    : "",
                  sections: [67],
                  appendKeys: _vm.questions[_vm.type],
                  planningQuestions: true,
                  hasAutosave: false
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("ApprovalBar", {
        attrs: { backUrl: "/reports/" + _vm.reportModel.uuid },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "actionContainer saving" },
                  [
                    _c(
                      "MiButton",
                      {
                        attrs: {
                          disabled: _vm.isSubmitting,
                          color: "primary",
                          small: "true",
                          icon: _vm.isSubmitting ? "" : "save"
                        },
                        nativeOn: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.submit()
                          }
                        }
                      },
                      [
                        _vm.isSubmitting
                          ? _c("LoaderCard", {
                              staticClass: "spinner--inline",
                              attrs: { type: "spinner--small" }
                            })
                          : _vm._e(),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.isSubmitting ? "Bezig met opslaan" : "Opslaan"
                            )
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }