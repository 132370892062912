import { Component, Vue, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Report as ReportModel } from '@/models/Report';
import { Rpc } from '@/models/Rpc';
import { Options } from '@/components/mi-dialog/MiDialog';

@Component<FurtherInvestigation>({})
export default class FurtherInvestigation extends Vue {
  @Prop()
  protected type!: string;

  public $pageTitle = 'further investigation';

  protected reportModel: ReportModel = new ReportModel();

  protected isLoading = true;

  protected isSubmitting = false;

  protected questions: {[key: string]: string[]} = {
    followUpInvestigation: [
      'VervolgOpnameHeader1',
      'VervolgOpnameHeaderInstruction',
    ],
    furtherInvestigation: [
      'NaderOnderzoekHeader1',
      'NaderOnderzoekInstruction',
      // 'NaderOnderzoekNoodzaak',
      // 'NaderOnderzoekHeader2',
      // 'NaderOnderzoekOmschrijving',
      // 'NaderOnderzoekSpecial',
      // 'NaderOnderzoekHeader3',
      // 'NaderOnderzoekKosten',
      // 'NaderOnderzoekDoorlooptijd',
      // 'NaderOnderzoekHeader4',
      // 'NaderOnderzoekPartijen',
    ],
    moreInvestigation: [
      'AanvullendOnderzoekHeader1',
      'AanvullendOnderzoekInstruction',
    ],
    reInvestigation: [
      'HeropnameHeader1',
      'HeropnameInstruction',
    ],
  }

  protected rpcSignature: {[key: string]: string} = {
    followUpInvestigation: 'additional-visit:create',
    furtherInvestigation: 'further-investigation:create',
    moreInvestigation: 'more-investigation:create',
    reInvestigation: 'heropname:create',
  }

  protected typeLabels: {[key: string]: string} = {
    followUpInvestigation: 'Vervolgopname',
    furtherInvestigation: 'Nader onderzoek',
    moreInvestigation: 'Aanvullend onderzoek',
    reInvestigation: 'Heropname',
  }

  protected mounted() {
    this.initialize();
  }

  protected submit() {
    this.isSubmitting = true;
    this.$store.dispatch('openDialog', this.confirmSaveDialogOptions);
  }

  protected createRpcCall() {
    const payload = this.createRpcPayload();

    new Rpc()
      .rpcPost(payload)
      .then(() => {
        this.$store.dispatch('closeDialog');
        this.$store.dispatch('openDialog', this.confirmDialogOptions);
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  private createRpcPayload() {
    return {
      signature: this.rpcSignature[this.type],
      body: {
        report: this.reportModel.case_number,
        answers: this.$store.state.answerPayload,
      },
    };
  }

  protected get confirmDialogOptions(): Options {
    return {
      title: `${this.typeLabels[this.type]} aangevraagd`,
      text: `${this.typeLabels[this.type]} succesvol aangevraagd`,
      type: 'success',
      buttons: {
        confirm: {
          text: 'Sluiten',
          color: 'success',
          action: () => {
            this.$router.back();
          },
        },
      },
    };
  }

  protected get confirmSaveDialogOptions(): Options {
    return {
      title: `${this.typeLabels[this.type]} aanvragen`,
      text: `Weet je zeker dat je een ${this.typeLabels[this.type]} wilt aanvragen?`,
      type: 'warning',
      buttons: {
        confirm: {
          text: 'Ja, aanvragen',
          color: 'success',
          action: () => {
            this.createRpcCall();
          },
        },
        cancel: {
          text: 'Nee, dat wil ik niet',
          color: 'warning',
          action: () => {
            this.$store.dispatch('closeDialog');
          },
        },
      },
    };
  }

  protected initialize() {
    this.isLoading = true;

    this.reportModel
      .include(['applicant', 'damages', 'media', 'type'])
      .find(this.$route.params.id)
      .then((report: ReportModel) => {
        this.reportModel = report;
        this.isLoading = false;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }
}
